<template>
  <div id="AccountPasswordUpdateSection">
    <!--    <p class="fs-14 mb-4" v-html="SectionData.displayEnftyData.content"></p>-->
    <!--    <h5 class="mb-3">  </h5>-->
    <div class="form" v-loading="loading">
<!--      <div class="mb-3">-->
<!--        <label for="EmailAddress" class="form-label">Email</label>-->
<!--        <div class="position-relative">-->
<!--          <input type="text" class="form-control form-control-s1" id="EmailAddress" placeholder="EMail address" :value="submitForm.email" readonly>-->
<!--        </div>-->
<!--      </div>-->

      <div class="mb-3">
        <label for="verificationCode" class="form-label">Verification Code sent to &nbsp;{{submitForm.email}} </label>
        <p v-html="this.alertText"></p>
        <div class="d-flex align-items-center">
          <div class="position-relative" style="flex: 1;width: 0">
            <input type="text" class="form-control form-control-s1" ref="verificationCode" id="verificationCode" placeholder="Enter Your Verify Code"
                   v-model="submitForm.verificationCode" @focus="inputfocus($event)" @input="validateNumberInput">
          </div>
          <div  class="ms-3" style="height: 100%">
            <button class="btn btn-grad" v-if="codeTime == 0" @click="code" :disabled="sendLoading">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="sendLoading"></span>
              <!--              <span class="visually-hidden">Send</span>-->
              Send
            </button>
            <button class="btn btn-dark disabled" disabled v-else>
              {{codeTime}}S
            </button>
          </div>
        </div>

      </div>
      <div class="mb-3">
        <label for="newPassword" class="form-label">New Password</label>
        <div class="position-relative">
          <input type="password" class="form-control form-control-s1" ref="newPassword" id="newPassword" placeholder="Enter New Password" v-model="submitForm.newPassword" @focus="inputfocus($event)">
          <a href="newPassword" class="password-toggle" title="Toggle show/hide pasword">
            <em class="password-shown ni ni-eye-off"></em>
            <em class="password-hidden ni ni-eye"></em>
          </a>
        </div>
      </div>

    </div>
    <div style="margin-top: 1.25rem" v-if="alert.show" class="alert-container">
      <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
    </div>
    <button style="width: 200px" class="btn btn-grad mt-4" type="button" @click="changePassword()">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
      Submit
    </button>
    <!--    <hr class="my-4">-->
    <!--    <h5 class="mb-3">{{ SectionData.displayEnftyData.titleThree }}</h5>-->
    <!--    <a href="#" class="btn btn-outline-dark">{{ // SectionData.displayEnftyData.btnTextTwo }}</a>-->
    <!--    <p class="fs-14 mt-2">{{ SectionData.displayEnftyData.contentTwo }}</p>-->
  </div><!-- end profile-setting-panel-wrap-->
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import coinAddressApi from "@/apis/coinAddressApi";
import emailApi from "@/apis/emailApi";
import pwdApi from "@/apis/pwdApi";
export default {
  name: 'AccountPasswordUpdateSection',
  data(){
    return {
      alertText: '<div class="alert-info d-flex mb-1" role="alert"><svg class="flex-shrink-0 me-2" width="25" height="25" viewBox="0 0 24 24" fill="#ff6a8e"><path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z"></path></svg><p class="fs-14">Click Send button to get code</p></div>',
      email: localStorage.getItem("email"),
      SectionData,
      alert: {
        text: "",
        show: false
      },
      submitForm: {
        email: localStorage.getItem("email"),
        verificationCode: "",
        newPassword: ""
      },
      loading: false,
      codeTime: 0,
      timer: 0,
      passwordText: '',
      enterMailErrorText: '',
      sendLoading: false
    }
  },
  created() {
    coinAddressApi.list(res => {
      this.coinAddress = res.data
    })
  },
  mounted() {
    /*  ======== Show/Hide passoword ======== */
    function showHidePassword(selector) {
      let elem = document.querySelectorAll(selector);
      if (elem.length > 0) {
        elem.forEach(item => {
          item.addEventListener("click", function (e) {
            e.preventDefault();
            let target = document.getElementById(item.getAttribute("href"));
            if (target.type == "password") {
              target.type = "text";
              item.classList.add("is-shown");
            } else {
              target.type = "password";
              item.classList.remove("is-shown");
            }
          });

        });
      }
    }
    showHidePassword(".password-toggle");
  },
  methods:{
    validateNumberInput(){
      let userInput = this.submitForm.verificationCode;
      if (!/^\d+$/.test(userInput)) {
        this.submitForm.verificationCode = userInput.replace(/\D/g, '');
      }
    },
    inputfocus(e) {
      console.log(e)
      e.srcElement.classList.remove('is-invalid');
      // this.alert.show = false;
    },


    //修改密码
    changePassword() {
      // const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-])[\w~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-]{8,}$/;
      const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[\w~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-]{8,}$/i;
      if (!this.submitForm.verificationCode && !this.submitForm.email || !this.submitForm.newPassword) {
        this.alert.show = true
        this.alert.text = "Please fill out the form"
        return
      }
      if(!passwordRegex.test(this.submitForm.newPassword)){
        this.alert.show = true
        this.alert.text = "Password must be a minimum of 8 characters and include at least one letter and one number."
        this.passwordText = this.alert.text
        this.$refs['newPassword'].classList.add('is-invalid')
        return
      }
      let data = JSON.parse(JSON.stringify(this.submitForm))
      data.newPassword = btoa(this.submitForm.newPassword)
      this.loading = true;
      // eslint-disable-next-line no-undef
      pwdApi.modify(data, res => {
        // loading.close()
        this.loading = false;
        if (res.code !== 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          //修改成并返回登录页面
          this.$message.success("success")
          // this.$router.push("/login")
        }
      })
    },


    code() {
      if (!this.submitForm.email) {
        this.alert.show = true
        this.alert.text = "Your email cannot be empty"
        this.emailErrorText = 'Your email cannot be empty'
        this.$refs['email'].classList.add('is-invalid')
        return;
      }

      this.sendLoading = true;
      emailApi.code({
        email: this.submitForm.email
      }, res => {
        this.sendLoading = false;
        if (res.code !== 200) {
          this.alert.show = true
          this.alert.text = res.msg
          // this.$message.error(res.msg)
        } else {
          this.$message.success("success")
          this.codeTime = 60
          this.timer = setInterval(() => {
            this.codeTime--
            if (this.codeTime === 0) {
              clearInterval(this.timer)
            }
          }, 1000)
        }
      })
    },



  },
  unmounted() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped lang="scss">
.alert-container {
  display: flex;
  align-items: center; /* 垂直居中对齐图标和文字 */
}


#AccountPasswordUpdateSection{
  background: #F8F9FC;
  border-radius: 8px;
  padding: 1.25rem;
  .form-floating {
    .invalid-feedback{
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
    }
    .form-control {

      &.password {
        &.is-invalid {
          margin-bottom: 3.5rem;
          //padding-right: calc(3.5em + 0.75rem);
          //background-position: right calc(2.8em + 0.1875rem) center;
          //background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }

      }

      &.is-invalid {
        margin-bottom: 1.65rem;
        background-image: none;
        border-color: var(--bs-form-invalid-border-color);
        //padding-right: calc(1.5em + 0.75rem);
        & ~ .invalid-feedback{
          display: block;
        }
      }

    }


    //background-repeat: no-repeat;
    //background-position: right calc(0.375em + 0.1875rem) center;
    //background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}
</style>
