<template>
<div class="Setting col-xl-6" >
  <div class="user-panel-title-box">
    <h2>Change Your Password</h2>
<!--            <h6 style="margin-top: 4px">We offer the following payment methods</h6>-->
<!--    <h4 class="text-primary mt-2">-->
<!--      Modify account password ?-->
<!--      <router-link to="/forgot-password" class="text-danger font-weight-bold"> Click Here</router-link>-->
<!--    </h4>-->
  </div><!-- end user-panel-title-box -->





  <div class="profile-setting-panel-wrap mt-5">

<!--    <ul class="nav nav-tabs nav-tabs-s1 nav-tabs-mobile-size" id="myTab" role="tablist">-->
<!--      <li class="nav-item" role="presentation" v-for="tab in tablist" :key="tab.id">-->
<!--        <button class="nav-link" :class="tab.isActive" :id="tab.slug" data-bs-toggle="tab" :data-bs-target="tab.bsTarget" type="button">{{ tab.title }}</button>-->
<!--      </li>-->
<!--    </ul>-->

    <div class="tab-content mt-4" id="myTabContent">
      <div class="tab-pane fade" :class="{active: panel.isActive, show: panel.isActive}" :id="panel.cTarget" role="tabpanel" :aria-labelledby="panel.slug" v-for="panel in tablist" :key="panel.id">
        <div class="profile-setting-panel">
<!--          <h5 class="mb-4"> {{panel.title}} </h5>-->
<!--          cTarget: 'AccountPassword'-->
          <template v-if="panel.cTarget === 'AccountPassword'">
            <AccountPasswordUpdateSection></AccountPasswordUpdateSection>
          </template>


        </div><!-- end profile-setting-panel -->
      </div><!-- end tab-pane -->
    </div>




<!--    <h4 style="">Payment Password Modification</h4>-->
<!--    <div class="form mt-3" v-loading="load">-->
<!--      <div class="d-flex align-items-center mb-4">-->
<!--        <div class="form-floating " style="flex: 1;width: 0">-->
<!--          <input type="text" ref="verificationCode" class="form-control " placeholder="Enter Your Mail Verification Code" v-model="submitForm.verificationCode" @focus="inputfocus($event)">-->
<!--          <label for="emailAddress">Mail Verification Code </label>-->
<!--          <div class="invalid-feedback">-->
<!--            {{enterMailErrorText}}-->
<!--          </div>-->

<!--        </div>&lt;!&ndash; end form-floating &ndash;&gt;-->
<!--        <div class="ms-3" style="height: 100%">-->
<!--          <button class="btn btn-dark" v-if="codeTime == 0" @click="code">-->
<!--            Send-->
<!--          </button>-->
<!--          <button class="btn btn-dark disabled" disabled v-else>-->
<!--            {{codeTime}}S-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->



<!--      <div class="form-floating mb-4">-->

<!--        <input type="password" ref="newPassword" class="form-control   password" id="newPassword" placeholder="newPassword" v-model="submitForm.newPassword" @focus="inputfocus($event)">-->
<!--        <label for="newPassword">New Payment Password</label>-->
<!--        <a href="password" class="password-toggle" title="Toggle show/hide pasword">-->
<!--          <em class="password-shown ni ni-eye-off"></em>-->
<!--          <em class="password-hidden ni ni-eye"></em>-->
<!--        </a>-->
<!--        <div class="invalid-feedback">-->
<!--          {{passwordText}}-->
<!--        </div>-->
<!--      </div>&lt;!&ndash; end form-floating &ndash;&gt;-->


<!--      <div style="margin-top: 1.25rem" v-if="alert.show">-->
<!--        <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>-->
<!--      </div>-->
<!--      <button class="btn btn-dark w-100 mt-4" type="button" @click="modification()">-->
<!--        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="load"></span>-->
<!--        Submit-->
<!--      </button>-->

<!--    </div>-->
  </div><!-- end profile-setting-panel-wrap-->
</div>
</template>

<script>
import emailApi from "@/apis/emailApi";
import walletApi from "@/apis/walletApi";
import coinAddressApi from "@/apis/coinAddressApi";
import AccountPasswordUpdateSection from "@/components/n1/AccountPasswordUpdateSection";
export default {
  name: "Setting",
  components: {AccountPasswordUpdateSection },
  data(){
    return {
      alert: {
        text: "",
        show: false
      },
      submitForm: {
        verificationCode: "",
        newPassword: ""
      },
      load: false,
      codeTime: 0,
      timer: 0,
      passwordText: '',
      enterMailErrorText: '',
      coinAddress: undefined,
      tablist: [
        {
          id: 1,
          isActive: 'active',
          title: 'Change Your Password',
          slug: 'account-password-tab',
          bsTarget: '#AccountPassword',
          cTarget: 'AccountPassword'
        },
        // {
        //   id: 2,
        //   title: 'Payment password',
        //   slug: 'payment-password-tab',
        //   bsTarget: '#PaymentPassword',
        //   cTarget: 'PaymentPassword'
        // },
      ]
    }
  },
  created() {
    coinAddressApi.list(res => {
      this.coinAddress = res.data
    })
  },
  methods:{
    inputfocus(e) {
      console.log(e)
      e.srcElement.classList.remove('is-invalid');
      // this.alert.show = false;
    },


    //修改密码
    modification() {
      if (this.submitForm.verificationCode === "") {
        this.alert.show = true
        this.alert.text = "Verification code cannot be empty"
        this.enterMailErrorText = this.alert.text
        this.$refs.verificationCode.focus()
        return
      }
      if (this.submitForm.newPassword === "") {
        this.alert.show = true
        this.alert.text = "New password cannot be empty"
        this.passwordText = this.alert.text
        this.$refs.newPassword.focus()
        return
      }
      let data = JSON.parse(JSON.stringify(this.submitForm))
      data.newPassword = btoa(this.submitForm.newPassword)
      this.load = true
      walletApi.modification(data, res => {
        this.load = false
        if (res.code !== 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          this.$message.success("Success")
          this.alert.show = false
          this.data = {
            verificationCode: "",
            newPassword: ""
          }
        }
      })
    },

    code() {
      const email = localStorage.getItem("email")
      // alert(email)
      if (!email) {
        this.$router.push("/sign-in")
        return
      }


      emailApi.code({
        email: email
      }, res => {
        if (res.code !== 200) {
          this.alert.show = true
          //错误返回信息
          this.alert.text = res.msg
          this.$message.error(res.msg)
        } else {
          this.$message.success("Success")
          //获取按钮禁用时间
          this.codeTime = 60
          this.timer = setInterval(() => {
            this.codeTime--
            if (this.codeTime === 0) {
              clearInterval(this.timer)
            }
          }, 1000)
        }
      })




    },



  },
  unmounted() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped lang="scss">
.form-floating {
  .invalid-feedback{
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
  }
  .form-control {

    &.password {
      &.is-invalid {
        margin-bottom: 3.5rem;
      //padding-right: calc(3.5em + 0.75rem);
      //background-position: right calc(2.8em + 0.1875rem) center;
      //background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }

    }

    &.is-invalid {
      margin-bottom: 1.65rem;
      background-image: none;
      border-color: var(--bs-form-invalid-border-color);
    //padding-right: calc(1.5em + 0.75rem);
      & ~ .invalid-feedback{
        display: block;
      }
    }

  }


//background-repeat: no-repeat;
//background-position: right calc(0.375em + 0.1875rem) center;
//background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
